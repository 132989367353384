import { FC } from "react";
import classNames from "classnames";

import { useBreakpoint } from "hooks/useBreakpoint";
import { ButtonInfo } from "components/ButtonInfo";
import { useSearch } from "context/SearchContext";
import { TOffer } from "pages/Search/Offer";
import { NoImage } from "components/NoImage";
import { TRelated } from "components/Related";

import styles from "./productInfo.module.scss";

export type TProduct = {
  id: string;
  article: string;
  brand: string;
  name: string;
  offer: TOffer[];
  relatedProducts: TRelated[];
  providerId: string;
  priceListId: string;
  exist: string;
  price: number;
  nomenclatureId?: string;
  image?: string | null;
  deliveryTime?: string;
  deliveryTimeText?: string;
  shownMore?: number;
};

interface IProductInfo {
  article: string;
  brand: string;
  name: string;
  nomenclatureId?: string;
  image?: string | null;
  product?: TProduct;
  className?: string;
  breakpoint?: string;
}

export const ProductInfo: FC<IProductInfo> = ({
  article,
  brand,
  name,
  nomenclatureId,
  image,
  product,
  className,
  breakpoint = "desktop"
}) => {
  // const { breakpoint, isDesktop } = useBreakpoint();
  const { setShownModal, setNomenclatureId } = useSearch();

  return (
    <div className={classNames(styles.product, className, styles[breakpoint])}>
      <div className={styles.image}>
        {image ? (
          <img
            src={`${process.env.REACT_APP_BASE_URL}/image.view?file=${image}`}
            alt={product?.name}
          />
        ) : (
          <NoImage className={styles.noImage} />
        )}
      </div>
      <div className={styles.info}>
        <div className={styles.article}>
          {brand} <span>{article}</span>
          {breakpoint === "desktop" && nomenclatureId && (
            <ButtonInfo
              onClick={() => {
                setShownModal(true);
                setNomenclatureId(nomenclatureId);
              }}
              breakpoint={breakpoint}
            >
              Инфо
            </ButtonInfo>
          )}
        </div>
        <div className={styles.name}>{name}</div>
      </div>
      {breakpoint !== "desktop" && (
        <>
          {nomenclatureId ? (
            <ButtonInfo
              onClick={() => {
                setShownModal(true);
                setNomenclatureId(nomenclatureId);
              }}
              breakpoint={breakpoint}
            />
          ) : (
            <div className={styles.noButtonInfo} />
          )}
        </>
      )}
    </div>
  );
};
