import { FC, useState } from "react";
import classNames from "classnames";

import { ButtonSmall } from "components/ButtonSmall";
import { ButtonInfo } from "components/ButtonInfo";
import { ReactComponent as CartIcon } from "assets/img/svg/cart.svg";
import { priceFormat } from "utils/format";
import { useSearch } from "context/SearchContext";
import { useCart } from "context/CartContext";
import { cartAdd } from "services/cartApi";
import { NoImage } from "components/NoImage";
import { TProduct } from "components/ProductInfo";
import { Spinner3 } from "components/Spinner3";

import styles from "./topProduct.module.scss";

interface ITopProduct {
  type: string;
  product: TProduct;
  breakpoint?: string;
}

export const TopProduct: FC<ITopProduct> = ({ type, product, breakpoint = "desktop" }) => {
  const searchContext = useSearch();
  const { setCart, setAdded } = useCart();
  const [loading, setLoading] = useState(false);

  const renderLabel = () => {
    switch (type) {
      case "byPrice":
        return "Минимальная цена";
      case "byDelivery":
        return "Быстрая доставка";
      case "byAnalogue":
        return "Аналог";
      default:
        return null;
    }
  };

  const cartHandler = () => {
    setLoading(true);
    cartAdd({
      ...product,
      count: 1
    })
      .then((res) => {
        setCart(res.cart);
        setAdded({
          ...product,
          id: res.added?.id,
          quantity: 1,
          relatedProducts: res.added?.relatedProducts
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!product) {
    return null;
  }

  console.log(product);

  return (
    <div className={classNames(styles.item, styles[type], styles[breakpoint])}>
      {breakpoint === "desktop" ? (
        <>
          <div className={styles.head}>
            <div className={styles.label}>{renderLabel()}</div>
            <div className={styles.delivery}>{product?.deliveryTimeText}</div>
          </div>
          <div className={styles.body}>
            <div className={styles.image}>
              {product?.image ? (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/image.view?file=${product?.image}`}
                  alt={product?.name}
                />
              ) : (
                <NoImage className={styles.noImage} />
              )}
            </div>

            <div className={styles.productData}>
              <div className={styles.infoBlock}>
                {product?.brand} <span>{product?.article}</span>
                {product?.nomenclatureId && (
                  <ButtonInfo
                    onClick={() => {
                      searchContext?.setShownModal(true);
                      if (product?.nomenclatureId) {
                        searchContext?.setNomenclatureId(
                          product?.nomenclatureId
                        );
                      }
                    }}
                    breakpoint={breakpoint}
                  >
                    Инфо
                  </ButtonInfo>
                )}
              </div>

              <div className={styles.priceBlock}>
                <div className={styles.price}>
                  {priceFormat(product?.price)} р.
                </div>
                <div className={styles.buyBlock}>
                  <div className={styles.quantity}>{product?.exist} шт.</div>
                  <div className={styles.buy}>
                    <ButtonSmall onClick={cartHandler} breakpoint={breakpoint}>
                      {loading ? (
                        <Spinner3 />
                      ) : (
                        "Купить"
                      )}
                    </ButtonSmall>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {breakpoint === "tablet" && (
            <div className={styles.image}>
              {product?.image ? (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/image.view?file=${product?.image}`}
                  alt={product?.name}
                />
              ) : (
                <NoImage className={styles.noImage} />
              )}
            </div>
          )}

          <div className={styles.leftBlock}>
            <div className={styles.label}>{renderLabel()}</div>
            <div className={styles.infoBlock}>
              {product.brand} <span>{product.article}</span>
            </div>
          </div>

          {product?.nomenclatureId && (
            <ButtonInfo
              onClick={() => {
                searchContext?.setShownModal(true);
                if (product?.nomenclatureId) {
                  searchContext?.setNomenclatureId(product?.nomenclatureId);
                }
              }}
              breakpoint={breakpoint}
            />
          )}

          <div className={styles.priceBlock}>
            <div className={styles.price}>{priceFormat(product.price)} р.</div>
            <div className={styles.delivery}>{product.deliveryTimeText}</div>
          </div>
          <div className={styles.buy}>
            <ButtonSmall onClick={cartHandler} breakpoint={breakpoint}>
              {loading ? <Spinner3 /> : <CartIcon />}
            </ButtonSmall>
          </div>
        </>
      )}
    </div>
  );
};
