import {
  FC,
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  KeyboardEvent
} from "react";
import { useBreakpoint } from "hooks/useBreakpoint";
import classNames from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { Button } from "components/Button";
import { searchPrice, suggestion } from "services/priceApi";
import useQuery from "hooks/useQuery";
import { useSearch } from "context/SearchContext";
import { useUser } from "context/UserContext";
import { Spinner2 } from "components/Spinner2";

import styles from "./searchField.module.scss";

import { ReactComponent as SearchWhiteIcon } from "./searchWhite.svg";

export const PROVIDERS = [
  "omega",
  "corona",
  "armtek",
  "favorit",
  "forum"
];

export const SearchField: FC = () => {
  const query = useQuery(useLocation);
  const location = useLocation();
  const autocompleteEl = useRef(null);
  const { user } = useUser();
  const { breakpoint, isMobile } = useBreakpoint();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [shownSuggestions, setShownSuggestions] = useState(false);
  const [progress, setProgress] = useState(0);
  const { setArticle, setBrand, article, brand, setResult, cleanResult, setSearchLoading } =
    useSearch();
  const [typingTimeout, setTypingTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  const searchPriceByProvider = (
    provider = "",
    article: string,
    brand?: string | null,
    callback?: () => void) => {
    if (search.length < 2 && article.length < 2) return;

    if (!user?.customerId) return;

    searchPrice({
      article,
      brand,
      userId: user?.id,
      provider
    })
      .then((res) => {
        setArticle(article);
        if (!res) {
          return;
        }

        if (!brand && res.info?.brand) {
          setBrand(res.info.brand);
        }

        setResult(res);

        if (callback) {
          callback();
        }
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setProgress(prev => (prev + 1)));
  };

  const searchHandler = (article: string, brand?: string | null) => {
    setArticle(article || "");
    if (!brand) {
      setBrand("");
    }
    cleanResult();
    setSearchLoading(true);
    setProgress(0);

    searchPriceByProvider("", article, brand, function () {
      setSearchLoading(false);
      PROVIDERS.forEach((provider, i) => {
        searchPriceByProvider(provider, article, brand);
      });
    });
  };

  useEffect(() => {

    if (!article) {
      if (location.search && location.pathname.includes("search")) {
        navigate({ pathname: "/search" });
      }
      return;
    };
    const url = new URLSearchParams();
    if (article) {
      url.append("article", article);
    }
    if (brand) {
      url.append("brand", brand);
    }

    if (url.toString()) {
      navigate({ pathname: "/search", search: `?${url}` });
    } else {
      navigate({ pathname: "/search" });
    }

    if (article.length > 2 && brand.length > 1) {
      searchHandler(article, brand);
    }
  }, [user?.customerId, article, brand]);

  const cleanSearch = () => {
    setSearch("");
    setArticle("");
    setBrand("");
    setResult(null);
    setSuggestions([]);
    setShownSuggestions(false);
  };

  const autocompleteHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setAutocompleteLoading(true);
    setSearch(e.target.value.trim());

    if (e.target.value.length < 2) {
      setSuggestions([]);
      setShownSuggestions(false);
      return;
    }

    setTypingTimeout(
      setTimeout(() => {
        suggestion({ search: e.target.value })
          .then((res) => {
            setSuggestions(Object.values(res));
            setShownSuggestions(true);
          })
          .finally(() => setAutocompleteLoading(false));
      }, 300)
    );
  };

  useEffect(() => {
    if (query.get("article")) {
      setArticle(query.get("article") || "");
      setSearch(query.get("article") || "");
    }
    if (query.get("brand")) {
      setBrand(query.get("brand") || "");
    }

    const closeResult = ({ target }: MouseEvent): void => {
      if (
        !autocompleteEl?.current ||
        !(autocompleteEl?.current as Node).contains(target as Node)
      ) {
        setShownSuggestions(false);
      }
    };
    document.addEventListener("click", closeResult);
    return () => document.removeEventListener("click", closeResult);
  }, []);

  useEffect(() => {
    if (suggestions.length > 0) {
      setShownSuggestions(true);
    } else {
      setShownSuggestions(false);
    }
  }, [suggestions]);

  const onClick = (article: string, brand: string) => {
    setArticle(article);
    setSearch(article);
    setBrand(brand);
    setShownSuggestions(false);
  };

  const focusHandler = () => {
    if (!suggestions.length) return;
    setShownSuggestions(true);
  };

  const onEnterPressHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.charCode === 13) {
      e.preventDefault();
      if (search.length < 2) return;
      setArticle((e.target as HTMLInputElement).value);
      searchHandler(search);
    }
  };

  return (
    <div
      className={classNames(styles.wrapper, styles[breakpoint])}
      ref={autocompleteEl}
    >
      <input
        className={styles.searchField}
        value={search}
        onChange={autocompleteHandler}
        maxLength={128}
        onFocus={focusHandler}
        onKeyPress={onEnterPressHandler}
      />
      {shownSuggestions && (
        <div className={styles.suggestions}>
          {suggestions.map(
            (res: {
              brand: string;
              name: string;
              partNumber: string;
              value: number;
            }) => (
              <div
                key={`${res.partNumber}${res.brand}`}
                className={styles.suggestionItem}
                dangerouslySetInnerHTML={{
                  __html: `<span>${res.partNumber.replace(
                    search,
                    `<strong>${search}</strong>`
                  )}</span> ${res.brand} ${res.name}`
                }}
                onClick={() => onClick(res.partNumber, res.brand)}
              />
            )
          )}
        </div>
      )}
      <div className={styles.rightBlock}>
        {autocompleteLoading ?
          <Spinner2 size={24} className={styles.spinner} />
          :
          <>{search &&
            <button onClick={cleanSearch} className={styles.cleanSearch} />
          }</>}

        <Button
          onClick={() => searchHandler(search)}
          className={styles.searchButton}
          breakpoint={breakpoint}
        >
          {isMobile ? <SearchWhiteIcon /> : "Поиск"}
        </Button>
      </div>
    </div>
  );
};
