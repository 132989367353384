import { TOffer } from "pages/Search/Offer";
import { createContext, useContext, useReducer, ReactNode } from "react";

// export interface ISearchContext {
//   article: string;
//   brand: string;
//   result: {} | null;
//   nomenclatureId: string;
//   shownModal: boolean;
//   searchLoading: boolean;
//   setResult: (result: any) => void;
//   setShownModal: (shownModal: boolean) => void;
//   setArticle: (article: string) => void;
//   setBrand: (brand: string) => void;
//   setNomenclatureId: (nomenclatureId: string) => void;
// }

export const SearchContext = createContext<any>(null);

// type TSearchPayload = {};

// interface ISearchAction {
//   type: string;
//   payload: string | boolean;
// }

export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "article":
      return { ...state, article: action.article };
    case "brand":
      return { ...state, brand: action.brand };
    case "result":

      if (!action.result) return state;

      if (action.result?.group) {
        return { ...state, result: action.result };
      }

      const output = state.result?.data ? { ...state.result } : { ...action.result };

      if (!action.result?.data) return state;

      for (const [brand1, brandData] of Object.entries(action.result?.data)) {

        if (!brandData) return;
        for (const [article1, articleData] of Object.entries(brandData)) {

          if (typeof output.data[brand1] === "undefined") {
            output.data[brand1] = brandData;
          }

          if (typeof output.data[brand1][article1] === "undefined") {
            output.data[brand1][article1] = articleData;
          }

          if (output.data[brand1][article1]) {
            if (output.data[brand1][article1].offer) {
              for (const offer of action.result?.data[brand1][article1].offer) {
                if (output.data[brand1][article1].offer.find((v: TOffer) => v.id === offer.id)) break;
                output.data[brand1][article1].offer = [
                  ...output.data[brand1][article1].offer,
                  offer
                ];
              }
            }
          } else {
            output.data[brand1][article1] = action.result?.data[brand1][article1];
          }

        }
      }

      return { ...state, result: output };

    case "cleanResult":
      return { ...state, result: null };
    case "nomenclatureId":
      return { ...state, nomenclatureId: action.nomenclatureId };
    case "shownModal":
      return { ...state, shownModal: action.shownModal };
    case "searchLoading":
      return { ...state, searchLoading: action.searchLoading };
    case "searchEnd":
      return { ...state, searchEnd: action.searchEnd };
    default:
      return state;
  }
};

export const useSearch = () => {
  return useContext(SearchContext);
};

interface ISearchProvider {
  children: ReactNode;
}

export const SearchProvider = ({ children }: ISearchProvider) => {
  const [state, dispatch] = useReducer(reducer, {
    article: "",
    brand: "",
    result: null,
    nomenclatureId: "",
    shownModal: false,
    searchLoading: false
  });

  const setArticle = (article: string) =>
    dispatch({ type: "article", article });

  const setBrand = (brand: string) => dispatch({ type: "brand", brand });

  const setSearchLoading = (searchLoading: boolean) =>
    dispatch({ type: "searchLoading", searchLoading });

  const setSearchEnd = (searchEnd: boolean) =>
    dispatch({ type: "searchEnd", searchEnd });

  const setResult = (result: any) => dispatch({ type: "result", result });

  const cleanResult = () => dispatch({ type: "cleanResult" });

  const setNomenclatureId = (nomenclatureId: string) =>
    dispatch({ type: "nomenclatureId", nomenclatureId });

  const setShownModal = (shownModal: boolean) =>
    dispatch({ type: "shownModal", shownModal });

  const value: any = {
    article: state.article,
    brand: state.brand,
    result: state.result,
    nomenclatureId: state.nomenclatureId,
    searchLoading: state.searchLoading,
    searchEnd: state.searchEnd,
    setArticle,
    shownModal: state.shownModal,
    setBrand,
    setSearchLoading,
    setSearchEnd,
    setResult,
    cleanResult,
    setNomenclatureId,
    setShownModal
  };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};
